type SingleColorIndex =
  | 'brand'
  | 'line'
  | 'line_contrasty'
  | 'shadow'
  | 'bg_default'
  | 'bg_darker'
  | 'text_default'
  | 'text_light'
  | 'text_lighter'
  | 'text_muted'
  | 'text_on_accent_bg'
  | 'accent'
  | 'focus_outline'
  | 'icon_disabled'

export type StatusColorIndex = 'success' | 'error'

const grays = [
  '#ffffff',
  '#f8fafc',
  '#f1f5f8',
  '#dae1e7',
  '#b8c2cc',
  '#8795a1',
  '#606f7b',
  '#3d4852',
  '#22292f',
  '#12161a',
  '#0a0d0f',
]

type Colors = { [key in SingleColorIndex | StatusColorIndex]: string } & {
  grays: string[]
}

const colors: Colors = {
  brand: '#663399',
  grays,
  success: '#6cb348',
  error: '#b34848',
  line: 'var(--line-default)',
  line_contrasty: 'var(--line-contrasty)',
  shadow: 'var(--shadow)',
  bg_default: 'var(--bg-default)',
  bg_darker: 'var(--bg-darker)',
  text_default: 'var(--text-default)',
  text_light: 'var(--text-light)',
  text_lighter: 'var(--text-lighter)',
  text_muted: 'var(--text-muted)',
  text_on_accent_bg: 'var(--text-on-accent-bg)',
  accent: 'var(--accent)',
  focus_outline: 'var(--focus-outline)',
  icon_disabled: 'var(--icon-disabled)',
}

const theme = {
  color: colors,
  maxWidth: '64rem',
  maxWidthNarrow: '32rem',
  vwImageCapWidth: 1138, // 64rem = 1024px. 1024px / 0.9 = 1138
  size: [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72].map(s => s / 16 + 'rem'),
  mq: {
    sm: '@media (min-width: 576px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 992px)',
  },
  elevation: [
    `0 1px 2px rgba(var(--shadow), 0.24),` +
      `0 1px 3px rgba(var(--shadow), 0.12)`,
    `0 2px 4px rgba(var(--shadow), 0.12),` +
      `0 3px 6px rgba(var(--shadow), 0.15)`,
    `0 3px 6px rgba(var(--shadow), 0.1),` +
      `0 5px 10px rgba(var(--shadow), 0.15)`,
    `0 5px 10px rgba(var(--shadow), 0.05),` +
      `0 10px 20px rgba(var(--shadow), 0.15)`,
    `0 20px 40px rgba(var(--shadow), 0.2)`,
  ],
  textShadow: '0 2px 4px rgba(34, 41, 47, 0.32)',
  easings: {
    // taken from easings.net
    easeInBack: 'cubic-bezier(0.6, -0.28, 0.735, 0.045)',
    easeOutBack: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    easeInOut: 'cubic-bezier(0.77, 0.2, 0.05, 1)',
    // taken from http://tobiasahlin.com/blog/how-to-animate-box-shadow/
    easeOut: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  },
  radius: {
    photo: '0.125rem',
    card: '0.25rem',
    button: '0.25rem',
  },
}

export type Theme = typeof theme

export default theme
