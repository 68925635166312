import { css } from '@emotion/core'

export const fontSmoothing = css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const systemFontStack = [
  'system-ui',
  '-apple-system' /* macOS 10.11-10.12 */,
  '"Segoe UI"' /* Windows 6+ */,
  '"Roboto"' /* Android 4+ */,
  '"Ubuntu"' /* Ubuntu 10.10+ */,
  '"Cantarell"' /* Gnome 3+ */,
  '"Noto Sans"' /* KDE Plasma 5+ */,
  'sans-serif' /* fallback */,
  '"Apple Color Emoji"' /* macOS emoji */,
  '"Segoe UI Emoji"' /* Windows emoji */,
  '"Segoe UI Symbol"' /* Windows emoji */,
  '"Noto Color Emoji"' /* Linux emoji */,
]

export function appendSystemFonts(mainFontFamily: string) {
  return [mainFontFamily, ...systemFontStack].join(',')
}

export const padding = css`
  padding: calc(2rem + 3%) 5%;
`
