import React, { ReactElement } from 'react'

type Props = {
  background?: 'light' | 'dark'
} & React.SVGAttributes<SVGElement>

export function ApostrofLogo({
  background = 'light',
  ...restProps
}: Props): ReactElement {
  const quoteFillColor = '#91a1be'
  const textFillColor = background === 'light' ? '#4a5568' : '#f1f4f8'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 41" {...restProps}>
      <g fillRule="nonzero" fill="none">
        <path
          fill={quoteFillColor}
          d="M6.152 17.68L11.336.88h-9.6l-.864 15.6z"
        />

        <path
          fill={textFillColor}
          d="M27.608 34.336l8.016-1.392-7.68-24.24H17.24L9.176 34H17l1.152-4.416h8.208l1.248 4.752zM24.776 23.68h-5.088l2.448-9.216h.192l2.448 9.216zM46.088 34v-6.768h1.104c7.296 0 11.328-3.6 11.328-9.504S54.296 8.8 47.432 8.8h-9.456V34h8.112zm1.152-12.48h-1.2v-6.912h1.104c2.736 0 3.888 1.392 3.888 3.408 0 2.064-1.056 3.504-3.792 3.504zm25.296 12.96c7.056 0 12.096-5.328 12.096-13.104 0-7.776-5.04-13.056-12.048-13.056s-12.048 5.328-12.048 13.104c0 7.776 5.04 13.056 12 13.056zm.048-6.432c-2.592 0-3.888-2.832-3.888-6.624s1.296-6.624 3.888-6.624c2.592 0 3.84 2.832 3.84 6.624s-1.2 6.624-3.84 6.624zm24.432 6.336c6.192 0 10.176-3.216 10.176-8.4 0-4.656-2.64-6.24-9.36-8.064-2.256-.624-2.784-1.056-2.784-1.968 0-.768.672-1.392 2.256-1.392 2.352 0 5.376.864 7.584 1.776l1.104-6.192c-1.872-.816-4.944-1.728-8.784-1.728-5.664 0-9.504 3.216-9.504 8.208 0 5.136 3.36 6.72 9.312 8.304 2.16.576 2.736 1.056 2.736 1.92 0 .864-.624 1.392-2.784 1.392-2.496 0-6.192-1.056-8.88-2.064l-1.2 6.384c2.64.96 6.288 1.824 10.128 1.824zM123.608 34V15.76h6.816l-1.008-6.96h-20.64v6.96h6.288V34h8.544zm23.328.48l8.448-1.392-5.184-8.544c2.64-1.536 4.08-4.368 4.08-7.152 0-5.136-3.36-8.592-10.608-8.592h-10.608V34h8.064v-6.912h1.68l4.128 7.392zm-4.272-13.344h-1.632v-6.432h1.776c2.16 0 3.6 1.008 3.6 3.072 0 2.256-1.248 3.36-3.744 3.36zM168.68 34.48c7.056 0 12.096-5.328 12.096-13.104 0-7.776-5.04-13.056-12.048-13.056s-12.048 5.328-12.048 13.104c0 7.776 5.04 13.056 12 13.056zm.048-6.432c-2.592 0-3.888-2.832-3.888-6.624s1.296-6.624 3.888-6.624c2.592 0 3.84 2.832 3.84 6.624s-1.2 6.624-3.84 6.624zM192.152 34v-8.832h7.776l-.912-6.192h-6.864v-4.032h9.408V8.8h-17.424V34h8.016z"
        />

        <path
          fill={quoteFillColor}
          d="M202 41l4.954128-17L212 25.2142857 211.174312 41z"
        />
      </g>
    </svg>
  )
}
