import '@/assets/fonts/loader.css'
import '@/assets/my-sanitize.css'
import { appendSystemFonts } from '@/utils/mixins'
import { css } from '@emotion/core'
import theme from './theme'

export default css`
  html {
    font-family: ${appendSystemFonts('"FF Meta"')};
    font-variant-numeric: lining-nums;
  }

  body {
    --bg-default: ${theme.color.grays[0]};
    --bg-darker: ${theme.color.grays[1]};
    --text-default: ${theme.color.grays[8]};
    --text-light: ${theme.color.grays[7]};
    --text-lighter: ${theme.color.grays[6]};
    --text-muted: ${theme.color.grays[5]};
    --text-on-accent-bg: white;
    --line-default: ${theme.color.grays[2]};
    --line-contrasty: ${theme.color.grays[3]};
    --accent: ${theme.color.brand};
    /* --shadow e.g.: 15, 15, 15 */
    /* Usage of --shadow e.g.: rgba(var(--shadow), 0.16) */
    --shadow: ${hexToRgbRaw(theme.color.grays[8])};
    --focus-outline: rgba(102, 51, 153, 0.5);
    --icon-disabled: ${theme.color.grays[3]};

    background-color: ${theme.color.bg_default};
    color: ${theme.color.text_default};
    transition: all 0.1s ease-out;
  }

  body.dark {
    --bg-default: ${theme.color.grays[9]};
    --bg-darker: ${theme.color.grays[10]};
    --text-default: ${theme.color.grays[0]};
    --text-light: ${theme.color.grays[1]};
    --text-lighter: ${theme.color.grays[3]};
    --text-muted: ${theme.color.grays[5]};
    --text-on-accent-bg: ${theme.color.grays[9]};
    --line-default: ${theme.color.grays[8]};
    --line-contrasty: ${theme.color.grays[7]};
    --accent: #ae5eff;
    --shadow: ${hexToRgbRaw(theme.color.grays[6])};
    --shadow: 0, 0, 0;
    --focus-outline: rgba(102, 51, 153, 0.8);
    --icon-disabled: ${theme.color.grays[7]};
  }

  a {
    color: ${theme.color.accent};
  }
`

function hexToRgbRaw(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `${r}, ${g}, ${b}`
}
