import { useTheme } from '@/store'
import { NavQuery } from '@/types/__generated__/NavQuery'
import { extractNodes, setPartiallyCurrent, useOnScreen } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useRef } from 'react'

const query = graphql`
  query NavQuery {
    allMainMenuYaml {
      edges {
        node {
          id
          text
          href
        }
      }
    }
    contentfulCompanyMetadata {
      logoClean {
        file {
          url
        }
      }
    }
  }
`

export const Nav: React.FC = () => {
  const theme = useTheme()
  const data = useStaticQuery<NavQuery>(query)
  const mainMenu = extractNodes(data.allMainMenuYaml)

  const sentinelRef = useRef<HTMLDivElement>(null)
  const isSentinelOnScreen = useOnScreen({
    ref: sentinelRef,
    initialState: true,
  })

  return (
    <>
      <div ref={sentinelRef} />

      <nav
        id="desktop-nav"
        className={isSentinelOnScreen ? '' : 'is-sticking'}
        css={css`
          padding: 0 5%;
          position: sticky;
          top: 0px;
          z-index: 5;
          background: ${theme.color.bg_default};

          display: none;

          ${theme.mq.sm} {
            display: block;
          }

          transition: box-shadow 0.1s ease-out;
          &.is-sticking {
            box-shadow: ${theme.elevation[3]};
          }
        `}
      >
        <div
          css={css`
            max-width: ${theme.maxWidth};
            margin: 0 auto;
          `}
        >
          <ul
            css={css`
              display: flex;
              justify-content: space-around;
              align-items: center;
              border: solid 1px ${theme.color.line};
              border-left: 0;
              border-right: 0;

              transition: border-color 0.1s ease-out;

              .is-sticking & {
                border-color: transparent;
                justify-content: flex-end;
                margin-left: -1rem;
                margin-right: -1rem;
              }
            `}
          >
            <li
              css={css`
                margin-right: auto;
                display: none;

                .is-sticking & {
                  display: block;
                }

                a {
                  display: flex;
                  align-items: center;
                  padding: 0.5rem 1rem;
                  height: 100%;
                }

                img {
                  width: 40px;
                }
              `}
            >
              <Link to="/">
                <img
                  src={data.contentfulCompanyMetadata.logoClean.file.url}
                  alt="CFS Paint Works Logo"
                />
              </Link>
            </li>
            {mainMenu.map(({ id, href, text }) => (
              <li
                key={id}
                css={css`
                  .is-sticking & {
                    margin-left: 2.5%;
                  }
                `}
              >
                <Link
                  to={href}
                  getProps={setPartiallyCurrent}
                  css={css`
                    display: block;
                    padding: 1rem;

                    color: ${theme.color.text_default};
                    text-decoration: none;
                    text-transform: lowercase;
                    font-size: ${theme.size[2]};

                    &:hover {
                      color: ${theme.color.accent};
                    }

                    &[data-partially-current='true'],
                    &[aria-current='page'] {
                      color: ${theme.color.accent};
                    }

                    transition: color 0.1s ease-out;
                  `}
                >
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  )
}
