import { useTheme } from '@/store'
import { MobileNavQuery } from '@/types/__generated__/MobileNavQuery'
import { extractNodes, hexToRGB, setPartiallyCurrent } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { FeatherIcon } from './FeatherIcon'

const query = graphql`
  query MobileNavQuery {
    allMainMenuYaml {
      edges {
        node {
          id
          text
          href
          featherIconName
        }
      }
    }
  }
`

export const MobileNav: React.FC = () => {
  const theme = useTheme()
  const data = useStaticQuery<MobileNavQuery>(query)
  const mainMenu = extractNodes(data.allMainMenuYaml)

  return (
    <nav
      id="mobile-nav"
      css={css`
        ${theme.mq.sm} {
          display: none;
        }

        position: fixed;
        z-index: 5;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${theme.color.bg_darker};
        box-shadow: 0 -8px 8px ${hexToRGB(theme.color.grays[8], 0.04)},
          0 0 4px ${hexToRGB(theme.color.grays[8], 0.08)};
        border-top: solid 1px ${theme.color.line};
      `}
    >
      <ul
        css={css`
          display: flex;
        `}
      >
        {mainMenu.map(({ id, href, text, featherIconName }) => (
          <li
            key={id}
            css={css`
              width: ${100 / mainMenu.length}%;
            `}
          >
            <Link
              to={href}
              getProps={setPartiallyCurrent}
              css={css`
                padding: 0.5rem 0;
                display: flex;
                flex-direction: column;
                align-items: center;

                text-decoration: none;

                &[data-partially-current='true'],
                &[aria-current='page'] {
                  box-shadow: inset 0 0 8px
                      ${hexToRGB(theme.color.grays[8], 0.16)},
                    inset 0 0 2px ${hexToRGB(theme.color.grays[8], 0.32)};
                  border-radius: 0 0 4px 4px;

                  /* One-off dark mode color toggler */
                  body.dark & {
                    color: ${theme.color.accent};
                  }
                }

                transition: box-shadow 0.1s ease-out;
              `}
            >
              <span
                css={css`
                  color: ${theme.color.text_lighter};

                  /* One-off dark mode color toggler */
                  body.dark [data-partially-current='true'] &,
                  body.dark [aria-current='page'] & {
                    color: inherit;
                  }
                `}
              >
                <FeatherIcon name={featherIconName} size={16} />
              </span>
              <p
                css={css`
                  margin-top: 0.25rem;

                  color: ${theme.color.text_light};
                  font-size: ${theme.size[0]};
                  text-transform: lowercase;

                  /* One-off dark mode color toggler */
                  body.dark [data-partially-current='true'] &,
                  body.dark [aria-current='page'] & {
                    color: inherit;
                  }
                `}
              >
                {text}
              </p>
            </Link>
          </li>
        ))}
      </ul>
      <div
        css={css`
          --safe-area-inset-bottom: env(safe-area-inset-bottom);
          height: var(--safe-area-inset-bottom);
          background-image: linear-gradient(
            -180deg,
            ${theme.color.grays[1]} 0%,
            ${theme.color.grays[3]} 100%
          );

          ${theme.mq.sm} {
            display: none;
          }

          /* One-off dark mode color toggler */
          body.dark & {
            background-image: linear-gradient(
              -180deg,
              ${theme.color.bg_darker} 0%,
              #000 100%
            );
          }
        `}
      />
    </nav>
  )
}
