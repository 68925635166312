import { useTheme } from '@/store'
import { HeaderLogoQuery } from '@/types/__generated__/HeaderLogoQuery'
import { css } from '@emotion/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'

const query = graphql`
  query HeaderLogoQuery {
    contentfulCompanyMetadata {
      logoClean {
        file {
          url
        }
      }
    }
  }
`

export const HeaderLogo: React.FC = () => {
  const theme = useTheme()
  const data = useStaticQuery<HeaderLogoQuery>(query)
  const { logoClean } = data.contentfulCompanyMetadata

  return (
    <Link
      to="/"
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;

        img {
          width: 355px;
        }

        p {
          margin-top: 1rem;
          color: ${theme.color.text_muted};
          font-size: ${theme.size[1]};
          padding-top: 0.5rem;
          padding-bottom: 0.75rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          border-top: dotted 1px ${theme.color.line_contrasty};
          border-bottom: dotted 1px ${theme.color.line_contrasty};
        }
      `}
    >
      <img src={logoClean.file.url} alt="CFS Painting Logo" />

      <p>Residential, Commercial, Repainting</p>
    </Link>
  )
}
