import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import {
  ExternalLink,
  extractNodes,
  MailtoLink,
  setPartiallyCurrent,
  createPhoneNumber,
} from '@apostrof/lib'
import { ApostrofLogo } from './ApostrofLogo'
import { useTheme } from '@/store'
import { FooterQuery } from '@/types/__generated__/FooterQuery'
import { fontSmoothing } from '@/utils/mixins'

const query = graphql`
  query FooterQuery {
    allMainMenuYaml {
      edges {
        node {
          id
          text
          href
        }
      }
    }
    contentfulCompanyMetadata {
      legalName
      email
      phone
      abn
      license
      operatingHours {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const Footer: React.FC = () => {
  const theme = useTheme()
  const data = useStaticQuery<FooterQuery>(query)
  const mainMenu = extractNodes(data.allMainMenuYaml)
  const {
    email,
    phone,
    legalName,
    abn,
    license,
    operatingHours,
  } = data.contentfulCompanyMetadata

  return (
    <footer
      css={css`
        margin-top: 6rem;
      `}
    >
      <section
        css={css`
          padding: 0 5%;
        `}
      >
        <div
          css={css`
            max-width: ${theme.maxWidth};
            margin: 0 auto;
            padding-top: 2rem;
            padding-bottom: 3rem;
            border-top: solid 1px ${theme.color.line};
            display: flex;
            justify-content: space-between;

            a {
              color: ${theme.color.text_default};
              text-decoration: none;
              font-size: ${theme.size[2]};

              transition: color 0.1s ease-out;

              &[data-partially-current='true'],
              &[aria-current='page'] {
                color: ${theme.color.accent};
              }
            }
          `}
        >
          <nav
            css={css`
              display: none;
              flex-grow: 1;
              padding: 0 1rem;
              border-right: solid 1px ${theme.color.line};

              ${theme.mq.sm} {
                display: block;
              }
            `}
          >
            <ul
              css={css`
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
              `}
            >
              {mainMenu.map(({ id, href, text }) => (
                <li
                  key={id}
                  css={css`
                    &:not(:first-of-type) {
                      margin-top: 1rem;
                    }
                  `}
                >
                  <Link
                    to={href}
                    getProps={setPartiallyCurrent}
                    css={css`
                      &:hover {
                        color: ${theme.color.accent};
                      }
                      text-transform: lowercase;
                    `}
                  >
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div
            css={css`
              flex-grow: 1;
              padding: 0 1rem;

              display: inline-flex;
              flex-direction: column;
              align-items: center;

              text-align: center;
              line-height: 1.25;

              a:hover {
                text-decoration: underline;
              }

              .phone,
              .operating-hours,
              .abn,
              .license {
                margin-top: 2rem;
              }

              .operating-hours ul {
                list-style-type: none;

                li + li {
                  margin-top: 0.25rem;
                }
              }
            `}
          >
            <MailtoLink email={email}>{email}</MailtoLink>

            <a
              href={`tel:+${createPhoneNumber({ phone, countryCode: '61' })}`}
              className="phone"
            >
              {phone}
            </a>

            <div
              className="operating-hours"
              dangerouslySetInnerHTML={{
                __html: operatingHours.childMarkdownRemark.html,
              }}
            />

            <p className="abn">ABN: {abn}</p>

            <p className="license">License: {license}</p>
          </div>
        </div>
      </section>

      <section
        css={css`
          /* 50px is the height of mobile nav */
          padding: 2rem 5% calc(2rem + 50px);
          background-image: linear-gradient(
            0deg,
            ${theme.color.grays[8]} 0%,
            ${theme.color.grays[7]} 100%
          );
          box-shadow: inset 0 4px 8px 0 ${theme.color.grays[8]};
          display: flex;
          flex-direction: column-reverse;
          align-items: center;

          ${theme.mq.sm} {
            flex-direction: row;
            padding-bottom: 2rem;
          }

          color: ${theme.color.grays[0]};
          ${fontSmoothing};

          .copyright {
            font-size: ${theme.size[1]};
          }

          .creator-link {
            order: 3;

            font-size: ${theme.size[0]};
            color: inherit;
            text-decoration: none;
            text-align: center;

            ${theme.mq.sm} {
              order: unset;
              margin-left: auto;
              margin-right: 5%;
            }

            .creator-name {
              margin-top: 0.5rem;
            }
          }

          .contentful-attribution-link {
            margin-top: 3rem;
            margin-bottom: 3rem;

            ${theme.mq.sm} {
              margin: 0;
            }
          }
        `}
      >
        <p className="copyright">&copy; {legalName}</p>
        <ExternalLink
          href="https://www.apostrof.co"
          title="Open link in new tab"
          className="creator-link"
        >
          <p>website designed &amp; built by</p>
          <ApostrofLogo background="dark" width={80} className="creator-name" />
        </ExternalLink>
        <ExternalLink
          href="https://www.contentful.com/"
          rel="nofollow"
          className="contentful-attribution-link"
        >
          <img
            src="https://images.ctfassets.net/fo9twyrwpveg/7Htleo27dKYua8gio8UEUy/0797152a2d2f8e41db49ecbf1ccffdaa/PoweredByContentful_DarkBackground_MonochromeLogo.svg"
            width={100}
            alt="Powered by Contentful"
          />
        </ExternalLink>
      </section>
      <div
        css={css`
          --safe-area-inset-bottom: env(safe-area-inset-bottom);
          height: var(--safe-area-inset-bottom);

          ${theme.mq.sm} {
            display: none;
          }
        `}
      />
    </footer>
  )
}
