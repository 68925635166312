import { useTheme } from '@/store'
import { css } from '@emotion/core'
import React from 'react'
import { HeaderLogo } from './HeaderLogo'

export const Header: React.FC = () => {
  const theme = useTheme()

  return (
    <header
      css={css`
        margin: 0 5%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: solid 1px ${theme.color.line};

        ${theme.mq.sm} {
          padding-top: 4rem;
          padding-bottom: 3rem;
          margin: 0;
          border: 0;
        }
      `}
    >
      <HeaderLogo />
    </header>
  )
}
