import { HeadIconsQuery } from '@/types/__generated__/HeadIconsQuery'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

const query = graphql`
  query HeadIconsQuery {
    contentfulCompanyMetadata {
      logoClean {
        file {
          url
        }
      }
    }
  }
`

export const HeadIcons: React.FC = () => {
  const data = useStaticQuery<HeadIconsQuery>(query)
  const { logoClean } = data.contentfulCompanyMetadata

  return (
    <Helmet>
      <html lang="en-US" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"
      />
      <link rel="icon" href="/favicon.png" type="image/png" />
      <link
        rel="apple-touch-icon-precomposed"
        href={`${logoClean.file.url}?fm=png&h=152&w=152&fit=scale`}
      />
    </Helmet>
  )
}
