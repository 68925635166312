// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-lookbook-tsx": () => import("../src/templates/single-lookbook.tsx" /* webpackChunkName: "component---src-templates-single-lookbook-tsx" */),
  "component---src-templates-single-project-tsx": () => import("../src/templates/single-project.tsx" /* webpackChunkName: "component---src-templates-single-project-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-portfolio-lookbooks-tsx": () => import("../src/pages/portfolio/lookbooks.tsx" /* webpackChunkName: "component---src-pages-portfolio-lookbooks-tsx" */),
  "component---src-pages-portfolio-projects-tsx": () => import("../src/pages/portfolio/projects.tsx" /* webpackChunkName: "component---src-pages-portfolio-projects-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

