import globalStyles from '@/config/globalStyles'
import theme from '@/config/theme'
import { ThemeProvider } from '@/store'
import { Global } from '@emotion/core'
import React from 'react'
import 'react-image-lightbox/style.css'
import '../assets/react-masonry-css.css'
import { DarkToggle } from './DarkToggle'
import { Footer } from './Footer'
import { Header } from './Header'
import { HeadIcons } from './HeadIcons'
import { MobileNav } from './MobileNav'
import { Nav } from './Nav'

const Layout: React.FC = ({ children }) => (
  <>
    <HeadIcons />

    <ThemeProvider value={theme}>
      <Global styles={globalStyles} />
      <DarkToggle />
      <Header />
      <Nav />
      <MobileNav />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  </>
)

export default Layout
