import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { css } from '@emotion/core'
import { FeatherIcon } from './FeatherIcon'
import { useTheme } from '@/store'

export const DarkToggle: React.FC = () => {
  const theme = useTheme()

  return (
    <ThemeToggler>
      {({ theme: colorMode, toggleTheme: toggleColorMode }: any) => {
        // Don't render anything at compile time. Deferring rendering until we
        // know which theme to use on the client avoids incorrect initial
        // state being displayed.
        if (colorMode == null) {
          return null
        }

        function toggle() {
          toggleColorMode(colorMode === 'dark' ? 'light' : 'dark')
        }

        return (
          <div
            css={css`
              padding: 0 5%;
            `}
          >
            <div
              css={css`
                max-width: ${theme.maxWidth};
                margin: 0 auto;
                color: ${theme.color.text_lighter};
                display: flex;
                justify-content: flex-end;
              `}
            >
              <button
                type="button"
                onClick={toggle}
                css={css`
                  padding: 1rem;
                  border: none;
                  background: none;
                  cursor: pointer;
                `}
              >
                <FeatherIcon name={colorMode === 'dark' ? 'moon' : 'sun'} />
              </button>
            </div>
          </div>
        )
      }}
    </ThemeToggler>
  )
}
